.cadastro-banner {
  height: 100%;
  position: relative;
  background-image: url(../../../static/img/banner-cadastro-bg.jpg);
  background-size: cover; }

.cadastro-banner__titulo {
  position: relative;
  padding: 0 8%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center; }
