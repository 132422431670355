.button {
  width: 100%;
  line-height: 2.666666667em;
  padding: 0 .666666667em;
  border-radius: 4px; }

.button--disabled {
  opacity: 0.56;
  cursor: not-allowed; }

.button--primary {
  background-color: #ECECEC; }

.button--secondary {
  background-color: #FFC107; }

.button--rounded {
  border-radius: 1.6rem;
  border: 1px solid #FFC107;
  background-color: transparent;
  padding: 0; }
  .button--rounded:hover {
    background-color: #FFC107; }

.button-odd {
  width: 100%;
  margin: 0 !important;
  padding: 0.8em;
  color: #FFC107; }

.button-odd--header {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 0.4rem; }

.button-odd--normal {
  background-color: #606060; }

.button-odd__label {
  white-space: nowrap; }

.button-odd--active {
  padding: 0.7em;
  border: 0.1em solid #FFC107; }
  .button-odd--active .button-odd__cotacao {
    color: white; }

.button-odd--disabled > span {
  color: white !important; }

.button-odd:hover {
  cursor: pointer; }

.button-odd:focus {
  outline: 0; }

.button-odd--green-animation {
  animation: blinkGreen 3.5s, fontGreen 13.5s step-end; }

.button-odd--red-animation {
  animation: blinkRed 3.5s, fontRed 13.5s step-end; }

@keyframes blinkGreen {
  0% {
    background-color: #505050; }
  10% {
    background-color: #60ba21; }
  20% {
    background-color: #505050; }
  30% {
    background-color: #60ba21; }
  40% {
    background-color: #505050; }
  50% {
    background-color: #60ba21; }
  60% {
    background-color: #505050; }
  70% {
    background-color: #60ba21; }
  80% {
    background-color: #505050; }
  90% {
    background-color: #60ba21; }
  100% {
    background-color: #505050; } }

@keyframes fontGreen {
  0% {
    color: #FFC107; }
  25% {
    color: #7ed321; }
  100% {
    color: #FFC107; } }

@keyframes blinkRed {
  0% {
    background-color: #505050; }
  10% {
    background-color: #dc1e28; }
  20% {
    background-color: #505050; }
  30% {
    background-color: #dc1e28; }
  40% {
    background-color: #505050; }
  50% {
    background-color: #dc1e28; }
  60% {
    background-color: #505050; }
  70% {
    background-color: #dc1e28; }
  80% {
    background-color: #505050; }
  90% {
    background-color: #dc1e28; }
  100% {
    background-color: #505050; } }

@keyframes fontRed {
  0% {
    color: #FFC107; }
  25% {
    color: #ff1e32; }
  100% {
    color: #FFC107; } }

.button-odd-rounded {
  width: 100%;
  border-radius: 0.4rem;
  padding: 0.9rem;
  background-color: #505050; }

.button-odd-rounded--active {
  padding: 0.8rem;
  border: 0.1rem solid #FFC107; }
  .button-odd-rounded--active .button-odd-rounded__title {
    color: white; }

.button-menu {
  font-size: 2.8rem;
  margin-right: 0.3em; }

.button-subevento-header .button-odd--active {
  background-color: rgba(0, 0, 0, 0.45); }
