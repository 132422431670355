.card-item-carrinho {
  margin-bottom: 0.8rem; }

.card-item-carrinho:only-child,
.card-item-carrinho:last-child {
  margin-bottom: 0; }

.card-item-carrinho__badge {
  background-color: #6A6A6A;
  padding: 0.7rem 0; }

.card-item-carrinho__icon {
  font-weight: bold;
  cursor: pointer;
  font-size: 1.5rem;
  display: inline-block;
  margin-right: 0.2rem; }

.card-item-carrinho p:first-child {
  color: white; }
