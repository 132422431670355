.sidebar {
  background-color: #424242;
  padding: 0 0.4rem 0.8rem; }

.sidebar-close {
  font-size: 2.4rem;
  color: white; }

.sidebar-full-width {
  min-width: 100% !important;
  max-width: 100%; }

.sidebar-left {
  max-width: 80%;
  width: 300px; }

.sidebar-left__header-row {
  margin-top: 0.8rem; }

.sidebar-left__close-column {
  margin-top: -0.4rem; }

.sidebar-left__close {
  margin-right: -0.4rem; }

.sidebar-right {
  min-width: 256px; }

.sidebar-right__close-column {
  margin: 0.4rem -0.4rem; }

.sidebar-full-width {
  min-width: 100% !important;
  max-width: 100%; }

.footer {
  text-align: center; }

.footer__regulamento {
  text-transform: none;
  margin-bottom: 0.8rem; }

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.pace-inactive {
  display: none; }

.pace .pace-progress {
  background: #FFC107;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px; }

.pace .pace-progress-inner {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #FFC107, 0 0 5px #FFC107;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px); }

.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 15px;
  right: 15px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #FFC107;
  border-left-color: #FFC107;
  border-radius: 10px;
  -webkit-animation: pace-spinner 400ms linear infinite;
  -moz-animation: pace-spinner 400ms linear infinite;
  -ms-animation: pace-spinner 400ms linear infinite;
  -o-animation: pace-spinner 400ms linear infinite;
  animation: pace-spinner 400ms linear infinite; }

@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes pace-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-ms-keyframes pace-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg); } }
