.card-evento__badge {
  background-color: #6A6A6A; }

.card-evento__badge--small {
  padding: 1.1rem 0; }

.card-evento__badge--medium {
  padding: 0.7rem 0; }

.card-evento__title {
  padding: 0.4rem 0; }

.card-evento__icon {
  cursor: pointer;
  font-size: 1.5rem;
  display: inline-block;
  margin-right: 0.4rem; }

.card-evento__icon--soma {
  font-weight: bold; }
