.telas-aovivo.telas-divididas {
  display: grid;
  grid-auto-flow: row;
  margin-bottom: 1.6rem; }

.stream__tabs {
  /* box-shadow: var(--shadow-level-1); */ }

.stream__tab,
.stream__tabs,
.stream__tab__video {
  height: 100%;
  border-radius: 0.4rem;
  overflow: hidden; }

.stream__tab__video {
  height: 100%;
  background-color: #606060;
  display: grid;
  align-items: center; }

.react-player {
  height: auto;
  border-radius: 0.4rem;
  overflow: hidden; }

.react-player iframe {
  height: 100%;
  width: 100%;
  border-radius: 0.4rem; }

.stream__tab__channels {
  padding: 0.8rem 0;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-gap: 0.8rem; }

.stream__tab__channel {
  padding: 0 0.8rem;
  font-weight: 600;
  font-size: 1.6rem;
  background-color: #606060;
  border-radius: 0.2rem;
  grid-column: auto;
  color: #565656; }

.stream__tab__channel.active {
  background-color: #FFC107;
  color: var(--light-primary); }

.stream__tabs.showPip {
  position: fixed;
  bottom: 3.2rem;
  right: 3.2rem;
  width: 300px;
  height: calc((300px / 16) * 9);
  background-color: #606060;
  z-index: 2;
  border-radius: 0.4rem; }

.loading__screen {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-rows: min-content min-content;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  grid-gap: 1.6rem; }

.stream__tabs.showPip .loading__screen {
  padding-top: 50px;
  padding-bottom: 50px;
  height: calc((300px / 16) * 9); }

.loading__screen span {
  font-weight: 600;
  font-size: 1.6rem; }

.stream.loading {
  background-color: #606060;
  border-radius: 0.4rem; }

.aviso-stream {
  height: calc((300px / 16) * 9);
  width: 100%;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  font-size: 1.6rem;
  padding: 1.6rem;
  text-align: center;
  background-color: #606060; }

.stream-action {
  cursor: pointer;
  color: #FFC107;
  font-weight: 600; }

.stream:not(.mobile) .stream__tabs:not(.showPip) .aviso-stream {
  height: calc((((84rem - 1.6rem) / 2) / 16) * 9); }

.mobile .stream__tabs:not(.showPip) .aviso-stream,
.mobile .loading__screen {
  height: calc(((100vw - 1.6rem) / 16) * 9); }

.mobile .stream__tabs.showPip,
.mobile .showPip .aviso-stream,
.mobile .showPip .loading__screen {
  height: 120px;
  width: calc((120px / 9) * 16); }

.mobile .stream__tabs.showPip {
  bottom: 7.2rem;
  right: 1rem; }

.mobile .aviso-stream {
  line-height: 150%; }
