.header-subevento {
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  width: 100%;
  background: url("https://s3.amazonaws.com/sitebets-assets/header-futebol.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.header-subevento__momento {
  padding: 1rem 0;
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0.45); }
