.date-test {
  color: #FFF;
  padding: 0.8rem 0;
  line-height: 2;
  appearance: none; }

.date-test:before {
  content: " ";
  display: block; }

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  overflow: auto;
  background-color: rgba(66, 66, 66, 0.72); }

.modal-container {
  margin: 0.8rem 0.4rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  background-color: #606060;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); }

.modal-header {
  margin-bottom: 0.8rem;
  min-height: 1.6rem;
  position: relative; }

.modal-header__close {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  top: 0;
  right: 0;
  position: absolute;
  cursor: pointer; }

.modal-edge-top {
  margin-top: 5%; }

.filtro-label {
  margin-bottom: 0.4rem; }

.filtro-label--empty:before {
  content: ".";
  visibility: hidden; }
