.eventoscamprint-linhajogos--colunanomejogo,
.eventoscamprint-linhajogos--colunacotacao {
  padding: 0.4rem 0px;
  border: solid 1px #FFC107;
  font-size: 1rem; }

.eventoscamprint-linhajogos--colunanomejogo {
  padding-left: 0.4rem; }

.eventoscamprint-linhajogos--colunacotacao {
  text-align: center; }

@media print {
  .eventoscamprint-linhajogos--colunanomejogo,
  .eventoscamprint-linhajogos--colunacotacao {
    border: solid 1px black; } }
