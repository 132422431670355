.ranking {
  background-color: #424242;
  cursor: pointer; }

.ranking__periodo {
  background: #6A6A6A;
  padding: 0.7rem 0; }

.ranking__cota-valor {
  position: relative;
  top: -0.6rem; }

.ranking__cota-icon {
  font-size: 2.2rem; }
