.nav {
  border-top: 1px solid #FFC107;
  background-color: #ECECEC; }

.nav--fetching .nav-divider {
  opacity: 0;
  visibility: hidden; }

.nav--small {
  position: relative;
  max-width: 800px;
  white-space: nowrap;
  overflow-x: auto; }

.nav--small:after {
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem; }

.nav-divider {
  display: inline-block;
  border-left: 1px solid #F5F5F5;
  border-right: 1px solid #D9D9D9;
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fetching-permissoes-enter {
  opacity: 0; }

.fetching-permissoes-enter.fetching-permissoes-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }
