.paper-snackbar {
  z-index: 999;
  transition-property: opacity, bottom, left, right, width, margin, border-radius;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  font-size: 1.6rem;
  min-height: 1.6rem;
  background-color: #323232;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  line-height: 2.4rem;
  padding: 1.6rem 2.4rem;
  bottom: 0;
  opacity: 0;
  left: 0;
  right: 0;
  min-width: 288px;
  max-width: 568px;
  border-radius: 2px;
  margin: 2.4rem auto;
  bottom: -100px; }

@media (max-width: 1023px) {
  .paper-snackbar {
    margin: 0;
    max-width: 100%; } }

.paper-snackbar .action {
  background: inherit;
  display: inline-block;
  border: none;
  font-size: inherit;
  text-transform: uppercase;
  color: #FFC107;
  margin-left: 2.4rem;
  padding: 0px;
  min-width: min-content; }
