.sidenav-container {
  width: 20%;
  display: block;
  float: left; }

.sidenav-divider {
  padding: 1.4em 0;
  width: 100%; }

.sidenav-list {
  background-color: transparent;
  padding: 0px;
  width: 100%; }

.sidenav-list__item {
  background-color: #606060;
  cursor: pointer; }

.sidenav-list__item--pais {
  background-color: #6A6A6A; }

.sidenav-list__item--pais:hover {
  background-color: #888888; }

.sidenav-list__item--modalidade:hover {
  background-color: #6A6A6A; }

.sidenav-list__item-content {
  padding: 1.5em 0.8em;
  border-top: 1px solid #6A6A6A;
  border-bottom: 1px solid #565656;
  border-left: 0 solid #FFC107; }

.sidenav-list__item-content--active {
  padding-left: 0.6em;
  background-color: #6A6A6A;
  border-left: 2px solid #FFC107; }

.sidenav-list__item-content-right {
  float: right; }

.sidenav-sublist {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out; }

.sidenav-sublist__item-content {
  border-bottom: 1px solid #565656;
  background-color: #6A6A6A; }

.sidenav-sublist__item-content--active .sidenav-sublist__item-content-left {
  color: #FFC107; }

.sidenav-sublist__item-content-left,
.sidenav-sublist__item-content-right {
  display: inline-block;
  padding-top: 1.5em;
  padding-bottom: 1.5em; }

.sidenav-sublist__item-content-left {
  float: left;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 2.17em;
  width: 73.333333333%; }

.sidenav-sublist__item-content-right {
  float: right;
  padding-right: 0.8em;
  width: 26.666666667%;
  text-align: right; }

.sidenav-fixlist {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out; }

.sidenav-fixlist__item-content {
  background-color: #6A6A6A;
  padding: 1.5em 0.8em 1.5em 3.35em;
  border-top: 1px solid #6A6A6A;
  border-bottom: 1px solid #565656; }

.sidenav-fixlist__item-content:hover {
  background-color: #888888; }

.sidenav-fixlist__item-content-right {
  float: right; }

.sublist-checkbox-wrapper {
  position: relative;
  color: white;
  padding-right: 2.3em; }

.sublist-checkbox-wrapper .checkbox {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%); }

.fixlist-checkbox-wrapper {
  position: relative;
  color: white; }

.fixlist-checkbox-wrapper .fixlist-checkbox-qtd {
  position: relative;
  color: white;
  padding-right: 2.3em; }

.fixlist-checkbox-wrapper .checkbox {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%); }
