.input-text {
  width: 100%;
  padding: 0 0.8rem;
  line-height: 3.2rem;
  border-radius: 4px; }

.input-text:disabled {
  opacity: 0.56;
  cursor: not-allowed; }

.input-text--error {
  background-image: url(../../../static/avisos/error.svg);
  background-repeat: no-repeat;
  background-position: right 0.8rem center;
  background-size: 2rem 2rem;
  line-height: 3rem;
  border: 1px solid #FF1E32;
  padding: 0 0.7rem; }

.input-text--primary {
  background-color: #F5F5F5; }

.input-text--secondary {
  background-color: #606060;
  color: white; }

.input-date {
  appearance: none; }

.checkbox {
  appearance: none;
  height: 18px;
  width: 18px;
  border: 2px solid #606060;
  border-radius: 2px;
  outline: none; }

.checkbox:checked {
  border-color: #FFC107;
  background: url("../../../static/font/check.svg") #FFC107 center no-repeat;
  outline: none; }

.input-sms, .input-cupom {
  position: relative; }

.input-sms__reenviar, .input-cupom__reenviar {
  position: absolute;
  right: 0.8rem;
  font-size: 2.4rem;
  top: 52%;
  transform: translateY(-50%); }

.input-cupom > .input-text--success {
  line-height: 2.8rem;
  border: 2px solid #7ED321;
  padding: 0px 0.6rem;
  background-image: url(../../../static/avisos/check.svg);
  background-repeat: no-repeat;
  background-position: right 3.4rem center;
  background-size: 2rem 2rem; }

.input-cupom > .input-text--error {
  border: 1px solid #FF1E32;
  background-position: right 3.4rem center; }

.input-palpite {
  background-color: transparent; }
