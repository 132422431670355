.hello-bar {
  padding: 0 0.4rem;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  cursor: pointer;
  background-color: #FFC107;
  text-align: center; }

.hello-bar__text {
  padding: 0.8rem 1.6rem 0.8rem 0; }

.hello-bar__close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.4rem;
  color: #424242;
  font-size: 1.5rem;
  font-weight: bold; }
