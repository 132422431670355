.configuracoes-saque input,
.configuracoes-inserir-creditos input,
.configuracoes-validar-usuario input,
.configuracoes-alterar-senha input {
  background-color: #606060; }

.style-info-bilhete-apostas header {
  background-color: #424242; }

.style-info-bilhete-apostas h3 {
  color: FFC107; }
