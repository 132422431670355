.filter {
  position: relative;
  max-width: 600px; }

@media screen and (max-width: 768px) {
  .filter:after {
    background: linear-gradient(to right, rgba(96, 96, 96, 0) 0%, #606060 100%);
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem; } }

.filter-list {
  margin-bottom: 0.8rem;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto; }

.filter-list__item {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: .666666667em .666666667em .6em .666666667em; }

.filter-list__item:last-child {
  margin-right: 2rem; }

.filter-list__item--active {
  border-bottom: 2px solid #FFC107; }
