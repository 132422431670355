.nav-link {
  display: inline-block;
  text-align: center; }

.nav-link--small {
  width: auto;
  padding: 1.6rem;
  min-width: 100px; }

.nav-link--large {
  width: 100%;
  padding: 1.6rem 0; }
