.configuracoes-extrato input {
  background-color: #606060; }

.filtro-label {
  margin-bottom: 0.4rem; }

.filtro-label--empty:before {
  content: ".";
  visibility: hidden; }

.extrato__saldo-atual {
  background: #424242; }

.extrato__valor-atual {
  float: right; }
