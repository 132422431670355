.carrinho {
  background-color: #606060;
  padding: 0.8rem 0; }

#valor-aposta {
  text-align: right; }

.carrinho-mobile {
  width: 56px;
  height: 56px;
  position: fixed;
  background-color: #FFC107;
  right: 8px;
  bottom: 8px;
  border-radius: 50%;
  transition-property: bottom;
  transition-duration: 0.3s;
  transition-timing-function: ease; }

.carrinho-mobile > .icon-carrinho {
  position: absolute;
  font-size: 2.4rem;
  top: 1.6rem;
  left: 1.6rem; }

.carrinho-mobile__qtd {
  z-index: 2;
  position: absolute;
  top: 1rem;
  left: 3rem;
  border-radius: 50%;
  background: #ff1e32;
  color: white;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  width: 1.4rem;
  height: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: -0.3px; }

.style-aposta-e-retorno {
  color: white; }

.input-nome {
  padding-bottom: 0.8rem; }
