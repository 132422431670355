.loader {
  display: inline-block;
  border: 2px solid transparent;
  border-top: 2px solid #FFC107;
  border-right: 2px solid #FFC107;
  border-left: 2px solid #FFC107;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 0.5s linear infinite; }

.loader--white {
  border-top: 2px solid #F5F5F5;
  border-right: 2px solid #F5F5F5;
  border-left: 2px solid #F5F5F5; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
