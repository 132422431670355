.card-header {
  background-color: #424242;
  position: relative; }

.card-badge {
  background-color: #6A6A6A;
  padding: 0.7rem 0; }

.card-content {
  background-color: #565656;
  padding: 0.8em; }

.card-block > .card-content {
  padding-bottom: 0; }

.card-evento-aposta > .card-content {
  background-color: #505050; }

.card-evento-aposta header {
  background-color: #424242; }

.card-no-content > .card-content {
  display: none; }
