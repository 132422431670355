.dropdown {
  position: relative;
  display: inline-block; }

.dropdown__button {
  cursor: pointer; }

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #F5F5F5;
  text-align: left;
  min-width: 23em;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
  z-index: 1; }

.dropdown-content.show {
  display: block; }

.dropdown-content__item {
  display: block;
  padding: 1.5rem;
  cursor: pointer; }

.dropdown-content__item:hover {
  background-color: #FFC107;
  color: white; }

.dropdown-content__info-usuario {
  display: block;
  padding: 1.5rem;
  border-bottom: 1px solid #D9D9D9; }
