.header-left__logo {
  display: inline-block; }

@media (min-width: 768px) {
  .header-left__logo {
    padding: 1.5rem 0; } }

.header-left__logo-img {
  max-height: 30px; }

.header-left__row-wrapper {
  margin: 0 !important; }
