.bolao-sidenav__item {
  background-color: #606060;
  padding: 1.5em 0.8em;
  cursor: pointer; }

.bolao-sidenav__item:hover {
  background-color: #6A6A6A; }

.bolao-sidenav__item--active {
  padding-left: 0.6em;
  background-color: #6A6A6A;
  border-left: 2px solid #FFC107; }

.bolao-sidenav__divider {
  padding: 1.4rem 0; }

.bolao-sidenav__divider:first-child {
  padding-top: 0.8rem; }

@media (max-width: 1023px) {
  .bolao-sidenav__divider:first-child {
    padding-top: 1.4rem; } }
