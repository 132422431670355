.configuracoes-apostas input {
  background-color: #606060; }

.filtro-label {
  margin-bottom: 0.4rem; }

.filtro-label--empty:before {
  content: ".";
  visibility: hidden; }

.info-aposta__toggle {
  margin-right: 0.2rem;
  font-size: 2rem;
  color: #606060;
  cursor: pointer; }

.info-bilhete-actions {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  justify-content: center;
  justify-items: center; }
  .info-bilhete-actions button {
    padding: 0 16px; }
