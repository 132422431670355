.main {
  background-color: #606060;
  padding: 0.8em 0; }

.main--block {
  padding: 0.8em 0 0 0; }

.evento-info {
  padding: 0.8rem;
  background: #565656; }

.carrinho-mobile-qtd {
  font-size: 10px;
  position: absolute;
  top: 0.9rem;
  left: 1.6rem;
  font-weight: bold;
  border-radius: 50%;
  background: white;
  width: 24px;
  height: 24px; }

.subevento-voltar__icon {
  font-size: 2.4rem;
  margin-right: 0.6rem; }

.subevento-voltar__text {
  position: relative;
  top: -0.4em; }

.subevento-estatisticas {
  font-size: 2rem;
  color: #FFC107;
  cursor: pointer; }
