.banner {
  display: block;
  min-height: 240px;
  max-height: 304px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-style: italic; }

.banner__wrapper {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 2px 4px 4px rgba(66, 66, 66, 0.38); }

.banner__info {
  margin-bottom: 20px; }

.banner__info:last-child {
  margin-bottom: 0; }

.banner__info--title {
  font-size: 3.2rem; }

.banner__info--vs {
  color: rgba(255, 255, 255, 0.87);
  text-transform: none; }

@media (max-width: 480px) {
  .banner__info--vs {
    display: block; } }

.banner__info--subtitle {
  font-size: 1.8rem;
  margin-bottom: 20px; }

.slick-arrow.slick-prev {
  z-index: 1;
  height: 100%;
  left: 0;
  padding-left: 0.8rem; }

.slick-arrow.slick-next {
  z-index: 1;
  height: 100%;
  right: 0;
  padding-right: 2.8rem; }
