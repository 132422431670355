.shadow-wrapper {
  position: relative; }

@media (max-width: 768px) {
  .shadow-wrapper:after {
    background: linear-gradient(to right, rgba(66, 66, 66, 0) 0%, #424242 100%);
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem; } }

.ranking-bilhetes {
  overflow-x: scroll;
  overflow-y: visible;
  margin-left: 100px; }

.ranking-bilhetes__table {
  width: 100%;
  border-collapse: collapse;
  text-align: left; }
  .ranking-bilhetes__table .fix-column {
    position: absolute;
    margin-left: -100px;
    width: 100px; }
  .ranking-bilhetes__table .max-width-150 {
    max-width: 150px; }
  .ranking-bilhetes__table thead, .ranking-bilhetes__table th.fix-column {
    background-color: #424242; }
  .ranking-bilhetes__table tbody, .ranking-bilhetes__table td.fix-column {
    background-color: #505050; }
  .ranking-bilhetes__table th, .ranking-bilhetes__table td {
    padding-right: 2.4rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    white-space: nowrap; }
  .ranking-bilhetes__table th:first-of-type, .ranking-bilhetes__table td:first-of-type {
    padding-left: 0.8rem; }
  .ranking-bilhetes__table th:last-of-type, .ranking-bilhetes__table td:last-of-type {
    padding-right: 0.8rem; }
