.numero-de-cotas {
  position: relative;
  height: 1.2rem;
  width: 80px;
  float: right; }

.numero-de-cotas__quantidade {
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: -1px;
  width: 20px; }

.numero-de-cotas__menos,
.numero-de-cotas__mais {
  position: absolute;
  font-size: 2rem;
  cursor: pointer; }

.numero-de-cotas__menos {
  left: 0; }

.numero-de-cotas__mais {
  right: 0; }
