@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
.iframe-jogo {
  width: 100%;
  height: 70vh; }

.jogo-imagemdestaque {
  position: relative; }

.jogos-jackpotheader {
  position: absolute;
  top: 0px;
  left: 4px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 8px 0px;
  z-index: 1; }

.jogos-jackpoticon {
  height: 35px;
  margin: 0 auto; }

.jogos-jackpotvalor {
  font-size: 2.3rem;
  padding-top: 5px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.jogos-linhajackpot {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.jogo-frame-seletor {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto 0; }

.jogo-frame-seletor:after {
  padding-top: 83%;
  display: block;
  content: ''; }

.card-buttons-selector {
  display: flex;
  flex-direction: row;
  grid-gap: 2rem;
  padding: 1.5rem 1.5rem;
  margin: 0 4px;
  position: absolute;
  background-image: linear-gradient(to bottom, transparent, black 50%);
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.33s ease-in-out; }

.jogos-item:hover > .card-buttons-selector {
  opacity: 1 !important; }

.jogos-item {
  position: relative;
  display: flex;
  flex-direction: column; }

.jogos-card-button-seletor-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  padding: 1.5rem 1.5rem;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  right: 0;
  bottom: 0;
  left: 0; }

.image-slot {
  z-index: -1; }
