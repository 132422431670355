.aviso {
  text-align: center;
  padding: 0.6rem; }

.aviso--default {
  border: 1px solid #FFC107; }

.aviso--error {
  border: 1px solid #FF1E32; }

.aviso--success {
  border: 1px solid #7ED321; }

.aviso--secondary {
  border: 1px solid #888888; }
