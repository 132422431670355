@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
.iframe-jogo {
  width: 100%;
  height: 70vh; }

.jogo-imagemdestaque {
  position: relative; }

.jogos-jackpotheader {
  position: absolute;
  top: 0px;
  left: 4px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 8px 0px; }

.jogos-jackpoticon {
  height: 35px;
  margin: 0 auto; }

.jogos-jackpotvalor {
  font-size: 2.3rem;
  padding-top: 5px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.jogos-linhajackpot {
  margin-left: 0px !important;
  margin-right: 0px !important; }
