.eticket {
  background-color: #606060;
  padding: 0.8rem 0; }

.eticket header {
  background-color: #424242; }

.card-eticket__container {
  cursor: pointer;
  margin: -0.8rem;
  padding: 0.8rem; }

.card-eticket__icon {
  font-weight: bold;
  cursor: pointer;
  font-size: 1.5rem;
  display: inline-block;
  margin-right: 0.2rem; }

.card-eticket__codigo {
  margin: 0.8rem 0 1.6rem 0;
  font-size: 2.4rem;
  position: relative; }

.card-eticket__codigo:after {
  content: "\E940";
  font-family: "Glyphicon";
  position: absolute;
  bottom: -0.025em;
  right: 6px;
  font-weight: normal;
  color: white; }

.detalhes-eticket__header {
  background-color: #424242;
  padding-top: 0.8rem; }

.card-eticket__qrcode {
  margin-bottom: 10px; }
  .card-eticket__qrcode canvas {
    border: 10px solid white; }

.card-eticket__container {
  cursor: pointer;
  margin: -0.8rem;
  padding: 0.8rem; }

.card-eticket__icon {
  font-weight: bold;
  cursor: pointer;
  font-size: 1.5rem;
  display: inline-block;
  margin-right: 0.2rem; }

.card-eticket__codigo {
  margin: 0.8rem 0 1.6rem 0;
  font-size: 2.4rem;
  position: relative; }

.card-eticket__codigo:after {
  content: "\e94d";
  font-family: "Glyphicon";
  position: absolute;
  font-size: 1em;
  bottom: -0.025em;
  right: 6px;
  font-weight: normal;
  color: white; }

.detalhes-eticket__header {
  background-color: #424242;
  padding-top: 0.8rem; }

.card-eticket__qrcode {
  margin-bottom: 10px; }

.card-eticket__qrcode canvas {
  border: 10px solid white; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }

.container-id-eticket {
  margin: auto; }

.container-button-copy {
  text-align: center;
  padding: 10px 20px 10px 20px;
  margin: 5px 10px 15px 10px;
  background-color: #424242; }

.container-copy-eticket-info {
  margin-top: 5px;
  margin-bottom: 10px; }

.container-copy-eticket-info button {
  padding: 10px;
  background-color: #424242; }

.card-info-eticket__copy {
  font-size: 1.2rem;
  position: relative; }

.card-info-eticket__copy::after {
  content: "\e94d";
  font-family: "Glyphicon";
  position: absolute;
  font-size: 12px;
  right: 6px;
  font-weight: normal;
  color: white; }

.info-eticket-cursor {
  cursor: pointer; }

.display-aviso {
  margin-bottom: 10px; }
